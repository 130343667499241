/* CSS HEX */
body,html{
    height:100%;}
.App {
    height: 100%;
}
:root {
    --charcoal: #264653ff;
    --persian-green: #2a9d8fff;
    --orange-yellow-crayola: #e9c46aff;
    --sandy-brown: #f4a261ff;
    --burnt-sienna: #e76f51ff;
    --off-white: #f0efeb;
    
}
#root {
    height: 100%;
}
body {
    background-color: var(--charcoal);
    color: var(--off-white);
    height: 100%;
}
.ContentContainer {
    display: inline-block
}
.Content {
  /* text-left: center; */
  background: url('images/transparent-background.png');
  display: inline-block;
  padding-left: 10%;
  padding-right: 5%;
  min-height: 100%;
}
.ContentFill {
    /* text-left: center; */
    /* display: inline-block; */
    padding-left: 10%;
    padding-right: 5%;
    height: 100%;
  }
.GHlogo {
    border-style: solid;
    border-radius: 10%;

    width: 20%;
    height: 20% auto;
    max-width: 120px;
}
.GHLogoLink {
    color: var(--burnt-sienna);
}
.GHLogoLink:hover {
    color: var(--orange-yellow-crayola);
}
.ItemList{
    list-style-type: none;
}
.ListGHmark {
    display: inline-block;
    vertical-align: middle;
    margin-left: 40%;
    width: 4vw;
    max-width: 30px;
    height: auto;
}
/* .ListDiv {
} */
.ListLink {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    font-size: 1.5em;
    color: var(--burnt-sienna);
}
.ListLink:hover {
    color: var(--orange-yellow-crayola);
}
.Header1{
    font-size: 3em;
}
.Header2{
    font-size: 2em;
}
/* .Hbackground{
    background-color: var(--charcoal);
} */
canvas {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
}